(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

function smooothClock() {
	if (document.body.classList.contains('template-all-contact-overview')) {
		function getCurrentTime(timeZone) {
			if (!timeZone)
				return new Date();
			else
				return new Date((new Date).toLocaleString('en-US', {timeZone}));
		}

		document.querySelectorAll('.contact-clock').forEach(function (svgClock) {
			const timeZone = svgClock.getAttribute('data-clock-timezone');
			const currentTime = getCurrentTime(timeZone);
			svgClock.style.setProperty('--start-seconds', currentTime.getSeconds());
			svgClock.style.setProperty('--start-minutes', currentTime.getMinutes());
			svgClock.style.setProperty('--start-hours', currentTime.getHours() % 12);
			console.log('%c♾️ Smoooth › Clock\n\n' + timeZone + ' timezone, clock set to ' + currentTime.getHours() + ':' + currentTime.getMinutes() + ':' + currentTime.getSeconds() + ' as start time', 'color: #cf2; font-size: 10px;');
		});
	}
}
;"use strict";

function smooothForms() {
	'use strict';

	// ;(function(document, window, index)
	// {
		var inputs = document.querySelectorAll( '.smoooth-form__row input[type="file"]' );
		Array.prototype.forEach.call( inputs, function( input ){
			var label	 = input.nextElementSibling,
				labelVal = label.innerHTML,
				selected = input.parentElement;

			input.addEventListener('change', function(e) {
				var fileName = '';
				var maxFiles = this.getAttribute('data-maxfiles');
				console.log(maxFiles);
				if( this.files && this.files.length > 1 ) {
					console.log('multiple files selected');
					if (this.files.length > maxFiles) {
						alert('Only ' + maxFiles + ' files accepted');
						fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
						//return false;
						this.value = '';
						fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
					} else {
						fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
					}
				} else {
					console.log('single file selected');
					fileName = e.target.value.split( '\\' ).pop();
				}
				console.log(fileName);
				if( fileName ) {
					selected.querySelector( '.selected-file' ).innerHTML = fileName;
				} else {
					label.innerHTML = labelVal;
				}
			});

			// Firefox bug fix
			input.addEventListener( 'focus', function(){ input.classList.add( 'has-focus' ); });
			input.addEventListener( 'blur', function(){ input.classList.remove( 'has-focus' ); });
		});
}
;"use strict";

function smooothLightbox() {
	//console.log(document.documentElement.lang);

	const customLightboxHTML = `<div id="glightbox-body" class="glightbox-container">
		<div class="gloader visible"></div>
		<div class="goverlay"></div>
		<div class="gcontainer">
		<div id="glightbox-slider" class="gslider"></div>
		<button class="gnext gbtn" tabindex="0" aria-label="Next" data-customattribute="example">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round">
				<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
				<path d="M9 9l3 3l-3 3"></path>
				<path d="M13 9l3 3l-3 3"></path>
				<path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"></path>
			</svg>
		</button>
		<button class="gprev gbtn" tabindex="1" aria-label="Previous">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round">
				<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
				<path d="M15 15l-3 -3l3 -3"></path>
				<path d="M11 15l-3 -3l3 -3"></path>
				<path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"></path>
			</svg>
		</button>
		<button class="gclose gbtn" tabindex="2" aria-label="Close">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round">
				<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
				<path d="M10 10l4 4m0 -4l-4 4"></path>
				<path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"></path>
			</svg>
		</button>
	</div>
	</div>`;

	let customSlideHTML = `<div class="gslide">
		<div class="gslide-inner-content">
			<div class="ginner-container">
				<div class="gslide-media">
				</div>
				<div class="gslide-description">
					<div class="gdesc-inner">
						<span class="gslide-title"></span>
						<div class="gslide-desc"></div>
					</div>
				</div>
			</div>
		</div>
	</div>`;

	const lightbox = GLightbox({
		selector: '[data-lightbox]',
		lightboxHTML: customLightboxHTML,
		slideHTML: customSlideHTML,
		skin: 'lightbox-smoooth',

		touchNavigation: true,
		loop: true,
		autoplayVideos: true,

		zoomable: false,

		slideEffect: 'slide',

		preload: true,

	});
	lightbox.on('open', (target) => {
		console.log('%c♾️ Smoooth › Lightbox\n\nLightbox opened', 'color: #cf2; font-size: 10px;');

	});
	lightbox.on('closed', (target) => {
		console.log('%c♾️ Smoooth › Lightbox\n\nLightbox closed', 'color: #cf2; font-size: 10px;');

	});

	lightbox.on('slide_before_change', ({ prev, current }) => {
		console.log('%c♾️ Smoooth › Lightbox\n\nPreparing transition', 'color: #cf2; font-size: 10px;');

		// Prev and current are objects that contain the following data
		const { slideIndex, slideNode, slideConfig, player, trigger } = current;
	});

	lightbox.on('slide_changed', ({ prev, current }) => {
		// console.log('Changed', prev);
		console.log('%c♾️ Smoooth › Lightbox\n\nDisplaying item #' + (current.slideIndex + 1), 'color: #cf2; font-size: 10px;');
		//console.log('Current slide', current);

		// Prev and current are objects that contain the following data
		const { slideIndex, slideNode, slideConfig, player, trigger } = current;

		// slideIndex - the slide index
		// slideNode - the node you can modify
		// slideConfig - will contain the configuration of the slide like title, description, etc.
		// player - the slide player if it exists otherwise will return false
		// trigger - this will contain the element that triggers this slide, this can be a link, a button, etc in your HTML, it can be null if the elements in the gallery were set dinamically

		if (player) {
			if (!player.ready) {
				// If player is not ready
				player.on('ready', (event) => {
					// Do something when video is ready
				});
			}

			player.on('play', (event) => {
				console.log('Started play');
			});

			player.on('volumechange', (event) => {
				console.log('Volume change');
			});

			player.on('ended', (event) => {
				console.log('Video ended');
			});
		}
	});
}
;"use strict";

function smooothPagetitle() {
    var pageTitle = document.title;
    if (document.documentElement.lang === 'nl') {
        var attentionMessage = '🤨 Waar ben je?';
    } else if (document.documentElement.lang === 'en') {
        var attentionMessage = '🤨 Where you at?';
    } else if (document.documentElement.lang === 'es') {
        var attentionMessage = '🤨 ¿Donde estás?';
    }

    document.addEventListener('visibilitychange', function() {
        var isPageActive = !document.hidden;
        if (!isPageActive) {
            document.title = attentionMessage;
        } else {
            document.title = pageTitle;
        }
    });
}
;"use strict";

function smooothPortfolio() {
	if (document.body.classList.contains('template-creative-portfolio-overview')) {

		let portfolioWrapper = document.querySelector('[data-ref^="portfolio-wrapper"]');
		let portfolioDropdown = document.querySelector('[data-ref^="portfolio-dropdown"]');

		let mixer = mixitup(portfolioWrapper, {
			load: {
				filter: 'all',
				sort: 'order:asc'
			},
			animation: {
				animateResizeContainer: true,
				animateResizeTargets: true,
				reverseOut: true,
				nudge: false, // Disable nudging to create a carousel-like effect
				clampHeight: false,
				clampWidth: false,
				duration: 1250,
				easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
				effectsIn: 'scale fade stagger(250ms)',
				effectsOut: 'scale fade translateY(20%) stagger(125ms)',
				perspectiveDistance: '2000px',
				queueLimit: 4,
				staggerSequence: function(i) {
					return (2*i) - (5*((i/3) - ((1/3) * (i%3))));
				}
			},
			callbacks: {
				onMixStart: function(state, futureState) {
					console.log('%c♾️ Smoooth › Portfolio\n\nSorting grid', 'color: #cf2; font-size: 10px;');
				},
				onMixBusy: function(state) {
					console.log('%c♾️ Smoooth › Portfolio\n\nSGrid being sorted', 'color: #cf2; font-size: 10px;');
				},
				onMixEnd: function(state) {
					console.log('%c♾️ Smoooth › Portfolio\n\nSorting grid complete', 'color: #cf2; font-size: 10px;');
				}
			},
			selectors: {
				control: '[data-ref^="portfolio-control"]',
				target: '[data-ref^="portfolio-item"]'
			},
		});

		let gridType = 'grid';
		if(portfolioWrapper.classList.contains('type-masonry')) {
			let gridType = 'masonry';
		}
		console.log('%c♾️ Smoooth › Portfolio\n\nInitializing grid \'type-' + gridType + '\'', 'color: #cf2; font-size: 10px;');

		let portfolioGrid = document.querySelector('[id^="portfolioWrapper"]');

		function resizeGridItem(item){
			let grid = portfolioGrid;
			let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
			let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
			let rowSpan = Math.ceil((item.querySelector('a').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
			item.style.gridRowEnd = 'span ' + rowSpan;
		}

		function resizeAllGridItems(){
			let allItems = document.getElementsByClassName('portfolio-item');
			for(let x=0; x<allItems.length; x++){
				resizeGridItem(allItems[x]);
			}
		}

		function resizeInstance(instance){
			let item = instance.elements[0];
			resizeGridItem(item);
		}

		let allItems = document.getElementsByClassName('portfolio-item');
		for(let x=0; x<allItems.length; x++){
			imagesLoaded( allItems[x], resizeInstance);
		}

		resizeAllGridItems();
		window.addEventListener('resize', resizeAllGridItems);

		mixer.show()
			.then(function() {
				// Remove the stagger effect for any subsequent operations

				mixer.configure({
					animation: {
						effects: 'fade scale'
					}
				});
			});

		portfolioDropdown.addEventListener('load', portfolioselectHandler, false);
		portfolioDropdown.addEventListener('change', portfolioselectHandler, false);

		function portfolioselectHandler(event) {
			// filter
			let selector = portfolioDropdown.value;
			mixer.filter(selector);

			// set data-attribute that controls the styling of the 'filter' svg using some CSS voodoo
			this.dataset.selected = this.value;

			// resize select
			let tempSelect = document.createElement('select'),
				tempOption = document.createElement('option');

			tempOption.textContent = event.target.options[event.target.selectedIndex].text;
			tempSelect.style.cssText += `visibility: hidden; position: fixed;`;
			tempSelect.appendChild(tempOption);
			event.target.after(tempSelect);

			const tempSelectWidth = tempSelect.getBoundingClientRect().width;
			event.target.style.width = `${tempSelectWidth}px`;
			tempSelect.remove();
		}

		portfolioDropdown.dispatchEvent(new Event('change'));
		portfolioDropdown.addEventListener('change', function(){
			let scrollDiv = document.getElementById('pageHeader').offsetTop;
			window.scrollTo({ top: scrollDiv - 60, behavior: 'smooth'});
		});

		function addClassNameListener(elemId, callback) {
			let elem = document.querySelector(elemId);
			let lastClassName = elem.className;
			const myInterval = setInterval(myTimer, 25);

			function myTimer() {
				let className = elem.className;
				if (className !== lastClassName) {
					mixer.destroy();
					console.log('%c♾️ Smoooth › Portfolio\n\nDestroyed grid', 'color: #cf2; font-size: 10px;');
					myStopFunction();
				}
			}
			myTimer();
			function myStopFunction() {
				clearInterval(myInterval);
			}
		}
		addClassNameListener('body', function(){  });
	}
}
;"use strict";

function smooothReferral() {
    const quotes = [
        'This website is built with love by ',
        'This website fits like a glove, oh so ',
        'Goood. Better. '
    ];
    const random = Math.floor(Math.random() * quotes.length);
    //const target = document.getElementsByClassName('smoooth-referral')[0];

    //target.innerHTML += '' + quotes[random] + '<a href="https://smoooth.digital/" target="_blank" style="text-decoration: underline;"><strong>Smoooth</strong></a>.';
}
;"use strict";

function smooothTooltip() {
	var elements = document.querySelectorAll('[data-smoooth-tooltip]'),
		smooothTooltipCount = 0;

	Array.prototype.forEach.call(elements, function (element) {
		let tooltips = document.getElementsByClassName('smoooth-tooltip');
		Array.prototype.forEach.call(tooltips, function (tooltip) { element.removeChild(tooltip); });
		let dataTooltip = element.dataset.smooothTooltip;
		element.addEventListener('mouseenter', () => { element.insertAdjacentHTML('beforeend', '<div class="smoooth-tooltip">' + dataTooltip + '</div>'); }, false);
		element.addEventListener('mouseleave', () => { let tooltip = document.getElementsByClassName('smoooth-tooltip')[0]; element.removeChild(tooltip); }, false );
		smooothTooltipCount++;
	});
	if (smooothTooltipCount != 0) {
		if (smooothTooltipCount < 2) {
			console.log('%c♾️ Smoooth › Tooltip\n\n' + smooothTooltipCount + ' tooltip found', 'color: #cf2; font-size: 10px;');
		} else {
			console.log('%c♾️ Smoooth › Tooltips\n\n' + smooothTooltipCount + ' tooltips found', 'color: #cf2; font-size: 10px;');
		}
	}
}
;"use strict";

function smooothVideos() {
	/*
		Extras for .smoooth-block-video
		Full list: https://stackoverflow.com/a/9897864/5603899
	 */
	var smmoothHTML5Video = Array.from(document.getElementsByTagName('video')),
		smooothHTML5VideoCount = 0;
	smmoothHTML5Video.forEach((child) => {
		child.addEventListener('canplaythrough', function (e) {
			if(child.currentTime == 0) {
				child.dataset.playstate = 'loaded';
				child.controls = false;
			}
		});
		child.addEventListener('seeking', function (e) {
			this.dataset.playstate = 'seeking';
			this.controls = true;
			this.pause();
		});
		child.addEventListener('seeked', function (e) {
			this.dataset.playstate = 'playing';
			this.play();
		});
		child.addEventListener('play', function (e) {
			this.dataset.playstate = 'playing';
			this.controls = true;
		});
		child.addEventListener('pause', function (e) {
			this.dataset.playstate = 'paused';
			this.controls = false;
		});
		child.addEventListener('ended', function (e) {
			this.dataset.playstate = 'ended';
			this.load();
			this.controls = false;
		});
		child.addEventListener('click', function(e) {
			if(this.dataset.playstate === 'loaded' || this.dataset.playstate === 'paused' || this.dataset.playstate === 'ended') {
				this.play();
			}
		});
		smooothHTML5VideoCount++;
	});
	if(smooothHTML5VideoCount != 0) {
		if(smooothHTML5VideoCount < 2) {
			console.log('%c♾️ Smoooth › HTML5 Video\n\n' + smooothHTML5VideoCount + ' video', 'color: #cf2; font-size: 10px;');
		} else {
			console.log('%c♾️ Smoooth › HTML5 Video\n\n' + smooothHTML5VideoCount + ' videos', 'color: #cf2; font-size: 10px;');
		}
	}
	var smooothExternalVideo = Array.from(document.querySelectorAll('.smoooth-block-video__posterimage')),
		smooothExternalVideoCount = 0;
	smooothExternalVideo.forEach((child) => {
		if(child.nextElementSibling.tagName == 'IFRAME') {
			child.addEventListener('click', function (e) {
				this.nextElementSibling.src = child.nextElementSibling.dataset.smooothVideoFacadeSrc;
				this.style.opacity = '0';
				this.style.pointerEvents = 'none';
				this.nextElementSibling.dataset.playstate = 'playing';
			});
		}
		smooothExternalVideoCount++;
	});
	if(smooothExternalVideoCount != 0) {
		if(smooothExternalVideoCount < 2) {
			console.log('%c♾️ Smoooth › Video Facade\n\n' + smooothExternalVideoCount + ' video', 'color: #cf2; font-size: 10px;');
		} else {
			console.log('%c♾️ Smoooth › Video Facade\n\n' + smooothExternalVideoCount + ' videos', 'color: #cf2; font-size: 10px;');
		}
	}
}
;function initSmoooth() {
	Prism.highlightAll();
	// var mixer = null;
	// if (mixer !== null) { mixer.destroy() }

	// initialize custom scripts
	smooothForms();
	smooothLightbox();
	smooothTooltip();
	smooothVideos();
	smooothPortfolio();
	smooothClock();
	smooothPagetitle();
	smooothReferral();
}

barba.init({
	debug: false,
	logLevel: 'error',
	async: true,
	transitions: [{
		// All hooks: https://barba.js.org/docs/advanced/hooks/
		// Current page once transition (browser first load)
		async once(data) {
			var footerHeight = document.getElementsByClassName('global-footer')[0].offsetHeight;
			document.documentElement.style.setProperty('--height-global-footer', footerHeight + 'px');
			initSmoooth();
		},
		// Before everything
		async before(data) {
			document.querySelector('html').classList.add('is-transitioning');
			document.getElementsByClassName('global-preloader')[0].classList.add('is-transitioning-in');
			await new Promise(r => setTimeout(r, 500));
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'auto'
			});
			await new Promise(r => setTimeout(r, 500));
			document.body.setAttribute('class', '');
			await new Promise(r => setTimeout(r, 500));
		},
		// Before leave transition/view
		async beforeLeave(data) {
			// ...
		},
		// Current page leave transition
		async leave(data) {
			// ...
		},
		// After leave transition/view (next container is DOM ready)
		async afterLeave(data) {
			// Get body class from incoming page end set the new body class
			let regexp = /\<body class=["](.+?)["]>/gi,
				match = regexp.exec(data.next.html);
			if (!match || !match[1]) {
				document.body.setAttribute('class', '');
			} else {
				document.body.setAttribute('class', match[1]);
			}
			// Get html language from incoming page and set it in case the visitor switches language
			let regexphead = /\<html lang=["](.+?)["] data-barba="wrapper">/gi,
				matchhead = regexphead.exec(data.next.html);
			if (!matchhead || !matchhead[1]) {
				document.html.setAttribute('lang', '');
			} else {
				document.documentElement.setAttribute('lang', matchhead[1]);
			}
		},
		// Before enter transition/view
		async beforeEnter(data) {
			// prevent barba from download-links
			Array.from(document.querySelectorAll(
				'[href^="/@/file/"], [data-lightbox], [target="_blank"]'
			)).forEach(element => {
				element.dataset.barbaPrevent = 'all';
			});
		},
		// Next page enter transition
		async enter(data) {
			initSmoooth();
		},
		// After enter transition/view
		async afterEnter(data) {
			// ...
			var footerHeight = document.getElementsByClassName('global-footer')[0].offsetHeight;
			document.documentElement.style.setProperty('--height-global-footer', footerHeight + 'px');
		},
		// After everything
		async after(data) {
			document.getElementsByClassName('global-preloader')[0].classList.add('is-transitioning-out');
			await new Promise(r => setTimeout(r, 1000));
			document.querySelector('html').classList.remove('is-transitioning');
			document.getElementsByClassName('global-preloader')[0].classList.remove('is-transitioning-in');
			document.getElementsByClassName('global-preloader')[0].classList.remove('is-transitioning-out');
		}
	}]
});

},{}],2:[function(require,module,exports){
// console.log('👻 wazzaaaaaa');

},{}]},{},[1,2]);
